import { UserRolePermissionDto } from '../../dtos/user-role-permission/user-role-permission.dto';
import { UserRolePermissionModel } from '../../models/user-role-permission/user-role-permission.model';

export class UserPermissionCreator {
  public static create(dto: UserRolePermissionDto): UserRolePermissionModel {
    return {
      name: dto.name,
      isShown: dto.value,
    };
  }
}
