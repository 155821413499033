import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { UserRolePermissionDto } from '../../dtos/user-role-permission/user-role-permission.dto';
import { UserRoleMenuTabPermissionDto } from '../../dtos/user-role-menu-tab-permission/user-role-menu-tab-permission.dto';

@Injectable({
  providedIn: 'root',
})
export class UserRolesHttpService {
  constructor(private httpClient: HttpClient) {}

  public getUserRolePermissions(): Observable<Array<UserRolePermissionDto>> {
    return this.httpClient
      .get<ApiResponseDto<Array<UserRolePermissionDto>>>(ApiUrlUtil.getApiUrl('user-role/users/permissions'))
      .pipe(map((response: ApiResponseDto<Array<UserRolePermissionDto>>) => response.data));
  }

  public getUserRoleMenuTabPermissions(): Observable<Array<UserRoleMenuTabPermissionDto>> {
    return this.httpClient
      .get<ApiResponseDto<Array<UserRoleMenuTabPermissionDto>>>(ApiUrlUtil.getApiUrl('user-role/users/menu-tabs'))
      .pipe(map((response: ApiResponseDto<Array<UserRoleMenuTabPermissionDto>>) => response.data));
  }
}
