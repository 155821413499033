import { UserRoleMenuTabPermissionDto } from '../../dtos/user-role-menu-tab-permission/user-role-menu-tab-permission.dto';
import { UserRoleMenuTabPermissionModel } from '../../models/user-role-menu-tab-permission/user-role-menu-tab-permission.model';

export class UserRoleMenuTabPermissionCreator {
  public static create(dto: UserRoleMenuTabPermissionDto): UserRoleMenuTabPermissionModel {
    return {
      name: dto.name,
      isShown: dto.show,
    };
  }
}
