import { Injectable } from '@angular/core';

import { forkJoin, map, Observable, of, tap } from 'rxjs';

import { AuthTokenService } from '../../../auth/services/auth-token/auth-token.service';
import { UserRoleMenuTabPermissionModel } from '../../models/user-role-menu-tab-permission/user-role-menu-tab-permission.model';
import { UserRolePermissionModel } from '../../models/user-role-permission/user-role-permission.model';
import { UserRolesService } from '../user-roles/user-roles.service';
import { UserRoleMenuTabNameModel } from '../../models/user-role-menu-tab-name/user-role-menu-tab-name.model';
import { UserPermissionNameModel } from '../../models/user-permission-name/user-permission-name.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserPermissionsService {
  private menuPermissionList: Array<UserRoleMenuTabPermissionModel>;
  private permissionList: Array<UserRolePermissionModel>;

  constructor(private authTokenService: AuthTokenService, private userRolesService: UserRolesService) {
    this.menuPermissionList = [];
    this.permissionList = [];
  }

  public initPermissions(): Observable<void> {
    if (!this.authTokenService.isAccessToken()) {
      return of(void 0);
    }

    return forkJoin([this.userRolesService.getUserRoleMenuTabPermissions(), this.userRolesService.getUserRolePermissions()]).pipe(
      tap(([menuPermissionList, permissionList]: [Array<UserRoleMenuTabPermissionModel>, Array<UserRolePermissionModel>]) => {
        this.setMenuPermissionList(menuPermissionList);
        this.setPermissionList(permissionList);
      }),
      map(() => void 0)
    );
  }

  public getMenuPermissionList(): Array<UserRoleMenuTabPermissionModel> {
    return this.menuPermissionList;
  }

  public getPermissionList(): Array<UserRolePermissionModel> {
    return this.permissionList;
  }

  public getShowValueForMenuTabName(menuTabName: UserRoleMenuTabNameModel): boolean {
    return !!this.getMenuPermissionList().find((tab: UserRoleMenuTabPermissionModel) => tab.name === menuTabName)?.isShown;
  }

  public getValueForPermissionName(permissionName: UserPermissionNameModel): boolean {
    return !!this.getPermissionList().find((permission: UserRolePermissionModel) => permission.name === permissionName)?.isShown;
  }

  private setMenuPermissionList(menuPermissionList: Array<UserRoleMenuTabPermissionModel>): void {
    this.menuPermissionList = menuPermissionList;
  }

  private setPermissionList(permissionList: Array<UserRolePermissionModel>): void {
    this.permissionList = permissionList;
  }
}
