import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { UserRoleMenuTabPermissionCreator } from '../../creators/user-role-menu-tab-permission/user-role-menu-tab-permission.creator';
import { UserPermissionCreator } from '../../creators/user-role-permission/user-role-permission.creator';
import { UserRoleMenuTabPermissionDto } from '../../dtos/user-role-menu-tab-permission/user-role-menu-tab-permission.dto';
import { UserRolePermissionDto } from '../../dtos/user-role-permission/user-role-permission.dto';
import { UserRoleMenuTabPermissionModel } from '../../models/user-role-menu-tab-permission/user-role-menu-tab-permission.model';
import { UserRolePermissionModel } from '../../models/user-role-permission/user-role-permission.model';
import { UserRolesHttpService } from '../user-roles-http/user-roles-http.service';

@Injectable({
  providedIn: 'root',
})
export class UserRolesService {
  constructor(private userRolesHttpService: UserRolesHttpService) {}

  public getUserRolePermissions(): Observable<Array<UserRolePermissionModel>> {
    return this.userRolesHttpService
      .getUserRolePermissions()
      .pipe(
        map((permissions: Array<UserRolePermissionDto>) =>
          permissions.map((permission: UserRolePermissionDto) => UserPermissionCreator.create(permission))
        )
      );
  }

  public getUserRoleMenuTabPermissions(): Observable<Array<UserRoleMenuTabPermissionModel>> {
    return this.userRolesHttpService
      .getUserRoleMenuTabPermissions()
      .pipe(
        map((menuTabs: Array<UserRoleMenuTabPermissionDto>) =>
          menuTabs.map((menuTab: UserRoleMenuTabPermissionDto) => UserRoleMenuTabPermissionCreator.create(menuTab))
        )
      );
  }
}
